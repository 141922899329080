@import url('https://fonts.googleapis.com/css2?family=Cuprum:wght@400;500;600;700&display=swap');

$sep_color: rgba(210,210,210,0.85);
$main_color: #232c33;


h1, h2, h3, h4, h5, h6, p, span, a, li, i, b, noscript, strong, input, button, div {
  font-family: 'Cuprum', sans-serif;
  font-weight: 600;
}

h1, h2 {
  text-shadow: rgb(20,20,20,0.325) 1px 1px 4px;
}

h3, h4, h5, h6, p, span, a, li, i, b, noscript, strong, input, button, div {
  text-shadow: rgb(25,25,25,0.2) 1px 1px 3px;
}

a {
  text-decoration: none !important;
  font-weight: 800;
  transition: 0.35s;

  &:hover {
    color: #32c35a !important;
  }
}

#root {
  background-color: $main_color;
}

#contact {
  background-color: #fafaff;
}

.gallery {
  column-count: 3;
  column-gap: 12px;
  margin-top: 8px;
  width: 85%;
  margin-bottom: 12px;
}

.gallery .pics {
  transition: all 350ms ease;
  margin-bottom: 8px;
}

.gallery .pics > img {
  border-radius: 6px;
}

.gallery .pics:hover {
  filter: opacity(0.8);
}

@media (max-width: 1050px) {
  .gallery {
    column-count: 2;
    padding: 0 8px;
    margin-top: 8px;
    width: 90%;
    column-gap: 8px;
  }

  .gallery .pics > img {
    border-radius: 4px;
  }
}

@media (max-width: 550px) {
  .gallery {
    column-count: 1;
    padding: 0 12px;
    margin-top: 8px;
    width: 90%;
  }

  .gallery .pics > img {
    border-radius: 4px;
  }

  .gallery .pics {
    margin-bottom: 12px;
  }
}

.fishmarketlesch {
  font-size: 105%;
}

.contactli {
  color: black !important;
}

.underline {
  border-bottom: rgba(245,245,245,0.75) 1px solid;
}

.underlineblack {
  border-bottom: rgba(10,10,10,0.9) 1px solid;
}

.phone_number {
  color: white;
}

.arttitle {
  color: rgba(15,15,15,0.75) !important
}

.previmgland {
  filter: brightness(0.5) blur(0.75px);
}

@media (min-width: 0px) and (max-width: 400px) {
  .headerleschname {
    font-size: 95%;
  }
}

@media (min-width: 400px) and (max-width: 800px) {
  .headerleschname {
    font-size: 110%;
  }
}

@media (min-width: 800px) {
  .headerleschname {
    font-size: 130%;
  }
}

.carousel-caption {
  padding: 12px !important;
  margin: 0;
  border-radius: 30px;
  backdrop-filter: blur(2px) brightness(0.75);
}

.carousel-caption > h3 {
  margin-bottom: 2px;
}

@media (max-width: 800px) {
  .carousel-caption {
    padding: 6px !important;
    margin: 0;
    border-radius: 20px;
    backdrop-filter: blur(2px) brightness(0.75);
  }

  .carousel-caption > h3 {
    margin-bottom: 2px;
    font-size: 130%;
  }

  .carousel-caption > p {
    padding-bottom: 8px;
    margin-bottom: 4px;
    font-size: 95%;
  }
}

.text-line {
  display: block;
  height: 1px;
  background-color: #a1a1a1;
  flex: 1 1;
}

.navlink {
  font-size: 130%;
}

.timework {
  color: #fe3c00 !important;
  margin-bottom: 16px;
  font-size: 145%;
  text-indent: 5px;
}

.contact_els li {
  font-weight: 400;
  font-size: 130%;
  color: #292929;
}

#timeworkcur {
  font-weight: 400;
  font-size: 115%;
  color: rgb(41,41,41,0.75);
  text-indent: 15px;
}

.contact_els li::marker {
  color: #e50022;
}

.splspan {
  display: flex;
  width: 100%;
  align-items: center;
}

.empty_gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: rgba(20,20,20,0.85)
}

.brandnamelogo {
  display: flex;
  align-items: center;
  margin-right: 28px !important;
  padding: 4px !important;
  border-radius: 6px;
  cursor: pointer;

  img {
    margin: 0 6px;
  }

  h2 {
    margin: 0;
  }
}

.map {
  padding: 0 0 0 12px !important;
}

.brandnamelogo:hover {
  color: white !important;
  filter: opacity(125%);
  background-color: rgba(255,255,255,0.025);
}

#header {
  background-color: $main_color;
  border-bottom: rgba(250,250,255,0.9) solid 2px;
}

#header .header_link:first-child {
  border: 0;
  border-left: $sep_color 1px solid;
}

#header .header_link:last-child {
  border-right: $sep_color 1px solid;
}

.header_link + .header_link {
  border: 0;
  border-left: $sep_color 1px solid;
}

.header_link {
  padding: 0 12px !important;
  font-size: 105% !important;
  transition: 0.4s;
}

.header_link:hover {
  filter: opacity(125%) contrast(135%);
  background-color: rgba(255,255,255,0.05);
  transition: 0.4s;
}

.footer {
  background-color: $main_color;
  color: white;
  border-top: rgba(250,250,255,0.9) solid 2px;

  .links {
      ul {
        list-style-type: none;
      }

      li:before {
        content: "— "
      }
    }

  .about-company{
    i {
      font-size: 25px;
    }
  }

  .location i {
    font-size: 18px;
  }

  .copyright p {
    border-top: 1px solid rgba(255,255,255,0.1);
  }
}

.shop_name {
  background-color: #0fc73a;
  display: block;
  font-size: 95%;
  border-radius: 24px 10px 40px 10px;
}

@media (max-width: 575px) {
  .shop_name {
    background-color: #0fc73a;
    display: block;
    font-size: 100%;
    border-radius: 24px 10px 40px 10px;
  }

  .footer_desc {
    font-size: 90%;
  }
}

.footer_link {
  padding: 2px 2px 4px 6px;
  border-radius: 10px 16px 5px 16px;
  background-color: #1c68be;

  a {
    text-decoration: none;
    font-weight: 600;
  }

  & + & {
    margin-top: 5px;
  }
}

#contact {
  padding: 8px 8px 8px 16px;
}
